import axios from "axios";
import { toast } from "react-toastify";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  progress: undefined,
  theme: "light",
};

const instance = axios.create({
  baseURL: `https://api.zahrasalonandspa.ca`,
  
});

instance.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      // If FormData, set the Content-Type header to multipart/form-data
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      // For other types of data, set the Content-Type header to application/json
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      // if it is post request then show toast message
      if(response.config.method === 'post' || response.config.method === 'put' || response.config.method === 'delete'){
      console.log(response,"response")
      toast.success(response.data.message);
      }
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // Handle unauthorized error
      toast.error("Unauthorized", toastOptions);
      // clear localStorage
      localStorage.clear();
    }
    if (error.response?.data?.message) {
      toast.error(error.response.data.message, toastOptions);
    } else if (error.message) {
      toast.error(error.message);
    } else if (error.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    return Promise.reject(error);
  }
);

export default instance;
