import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useFormik } from "formik";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { ServicesApi, } from "../../../apis";
import { makeStyles } from "@mui/styles";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
  },
});

const AddServiceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = !!id;
  // useSearchParams
  const [searchParams] = useSearchParams();

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [addOns, setAddOns] = useState([{ key: "", price: "" }]);
  const [editorState, setEditorState] = useState('');
  const [service, setService] = useState(null); // State to store service data


  const validationSchema = yup.object({
    serviceId: yup.string().required("Service ID is required"),
    price: yup.number().positive(),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),

  });
  useEffect(() => {
    if (searchParams.get("serviceId") !== null) {
      // Fetch existing service details
      ServicesApi.getServiceDetailById(id)
        .then((res) => {
          const serviceData = res.data;
          console.log(res.data);
          setService(serviceData);
          formik.setFieldValue("price", res.data.data.price);
          formik.setFieldValue("title", res.data.data.title);
          formik.setFieldValue("description", res.data.data.description);
          formik.setFieldValue("image", res.data.data.image);

        });
    }
  }, [id]); // Fetch details when id changes


  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      serviceId: id,
      price: "",
      title: "",
      description: "",
       // addOns: addOns,
      image: null, // Update to handle single image field correctly
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("serviceId", values.serviceId);
      formData.append("price", values.price);
      formData.append("title", values.title);
      formData.append("description", values.description);

      // Check and append image if it's a valid file
      if (values.image instanceof File) {
        formData.append("image", values.image);
      }

      let serviceID = searchParams.get("serviceId");
      if (serviceID) {
        ServicesApi.updateServiceDetails(id, formData).then(() => {
          navigate(`/services/detail/${serviceID}`, { replace: true });
        });
      } else {
        ServicesApi.addServicesDetail(formData).then(() => {
          navigate(`/services/detail/${id}`, { replace: true });
        });
      }
    }
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("image", file);
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    setImagePreview(null);
    formik.setFieldValue("image", null); // Reset the image field correctly
  };


  // const handleImageChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setImages(files);
  //   // Create image previews for each file
  //   const previews = files.map((file) => URL.createObjectURL(file));
  //   setImagePreviews(previews);
  //   formik.setFieldValue("images", files);
  // };

  // const handleImageDelete = (index) => {
  //   // Remove the selected image and its preview from the state
  //   const updatedImages = [...images];
  //   updatedImages.splice(index, 1);
  //   setImages(updatedImages);
  //   const updatedPreviews = [...imagePreviews];
  //   updatedPreviews.splice(index, 1);
  //   setImagePreviews(updatedPreviews);
  //   // Update formik field value
  //   formik.setFieldValue("images", updatedImages);
  // };

  // const handleAddOnChange = (index, field, value) => {
  //   const updatedAddOns = [...addOns];
  //   updatedAddOns[index][field] = value;
  //   setAddOns(updatedAddOns);
  //   formik.setFieldValue("addOns", updatedAddOns);
  // };

  // const handleAddOnAdd = () => {
  //   setAddOns([...addOns, { key: "", price: "" }]);
  // };

  // const handleAddOnRemove = (index) => {
  //   const updatedAddOns = addOns.filter((_, i) => i !== index);
  //   setAddOns(updatedAddOns);
  //   formik.setFieldValue("addOns", updatedAddOns);
  // };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => {
              let backId = searchParams.get("serviceId") !== null ? searchParams.get("serviceId") : id;
              navigate(
                `/services/detail/${backId}`, { replace: true }
              )
            }} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              {
                searchParams.get("serviceId") !== null ? "Edit" : "Add"
              } Service Details
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                "&:hover": {
                  background: "secondary.main",
                  outline: "none",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Price:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="price"
                  name="price"
                  type="number"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.price && Boolean(formik.errors.price)
                  }
                  helperText={formik.touched.price && formik.errors.price}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Title:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            {/* Image upload section */}
            <Grid item xs={6}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Image:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="image-upload"
                />
                <label htmlFor="image-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      height: "55px",
                      textTransform: "none", color: "black"
                    }}
                  >
                    Upload Image
                  </Button>
                </label>
                {/* Display image preview */}
                {imagePreview && (
                  <Box
                    mt={2}
                    position="relative"
                    sx={{ display: "inline-block", position: "relative" }}
                  >
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img
                        src={imagePreview}
                        alt="Uploaded preview"
                        width={100}
                        height={100}
                        style={{ display: "block" }}
                      />
                      <IconButton
                        onClick={handleImageDelete}
                        sx={{
                          position: "absolute",
                          top: "-8px",
                          right: "-10px",
                          color: "grey",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Box>
                )}
                {!imagePreview && isEditMode && service && service.data.image && (
                  <Box mt={2} position="relative" sx={{ display: "inline-block" }}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/${service.data.image}`} // Adjust base URL
                      alt="Uploaded preview"
                      width={100}
                      height={100}
                      style={{ display: "block" }}
                    />
                    {/* <IconButton
        onClick={handleImageDelete}
        sx={{
          position: "absolute",
          top: "-8px",
          right: "-10px",
          color: "grey",
        }}
      >
        <DeleteIcon />
      </IconButton> */}
                  </Box>
                )}

                {/* Display caption */}
                <Typography variant="caption" color="black" ml={1}>
                  {imagePreview && "Image uploaded successfully"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Description:
              </InputLabel>
              <CKEditor

                editor={ClassicEditor}
                data={
                  formik.values.description
                }
                onReady={editor => {
                  // You can also set the editor UI height dynamically here if needed
                  editor.ui.view.editable.element.style.height = '400px';
                }}
                config={{

                  ckfinder: {
                    uploadUrl: 'https://api.zahrasalonandspa.ca/api/service/upload-image',
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorState(data);
                  formik.setFieldValue("description", data);
                }}
              />
            </Grid>


          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddServiceDetails;
